import request from "@/utils/request";
/**
 * 创建菜单【ok】
 * @param params
 * @returns {*}
 */
export function createMenu(params) {
  return request({
    url: "/api/backend/setting/permission/createMenu",
    method: "post",
    data: params
  });
}

/**
 * 查询所有的菜单列表【ok】
 * @param params
 * @returns {*}
 */
export function getMenuList(params) {
  return request({
    url: "/api/backend/setting/permission/getMenuList",
    method: "get",
    params
  });
}

/**
 * 查询当前用户的菜单列表
 * @param params
 */
export function getMenuListByCurrentUser(params) {
  return request({
    url: "/api/backend/setting/permission/getMenuList",
    method: "get",
    params
  });
}
